<template lang="pug">
  .inventory-groups-table(@click.stop="$emit('unselect-inventory-group')")
    table
      thead
        tr
          th.inventory-group-code.sortable(@click.stop="handleSorting('code')")
            span {{ $t("company_system.shop_settings.table.inventory_group_code") }}
            FaIcon.icon(:icon="sortingIcon('code')")
          th.inventory-group-name.sortable(@click.stop="handleSorting('name')")
            span {{ $t("company_system.shop_settings.table.inventory_group_name") }}
            FaIcon.icon(:icon="sortingIcon('name')")
          th.updated-at.sortable(@click.stop="handleSorting('updated_at')")
            span {{ $t("company_system.shop_settings.table.updated_at") }}
            FaIcon.icon(:icon="sortingIcon('updated_at')")
          th.actions
      tbody
        InventoryGroupRow(
          v-for="(inventoryGroup, index) in inventoryGroups"
          :key="inventoryGroup.id || inventoryGroup._addedAt"
          :index="index"
          :selected-index="selectedInventoryGroupIndex"
          :inventory-group="inventoryGroup"
          :has-edit-permission="hasEditPermission"
          @change-inventory-group="$emit('change-inventory-group', $event)"
          @select="$emit('select-inventory-group', $event)"
          @delete="$emit('delete-inventory-group', $event)"
        )
</template>

<script>
  // mixins
  import withSorting from "@/mixins/withSorting"

  export default {
    mixins: [withSorting],

    props: {
      hasEditPermission: {
        type: Boolean,
        default: true
      },
      selectedInventoryGroupIndex: {
        type: Number,
        required: false
      },
      inventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      sortingData: Object
    },

    components: {
      InventoryGroupRow: () => import("./InventoryGroupRow")
    }
  }
</script>

<style lang="sass">
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"

  .inventory-groups-table
    +listing-container(83px)

    table
      +custom-table
      +matchings-table-header-sticky
      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          &.inventory-group-code
            width: 10%

          &.inventory-group-name
            width: 20%

          &.updated-at
            width: 15%

          &.actions
            width: 5%
</style>
